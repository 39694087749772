<template>
  <el-tabs class="test" type="border-card" stretch>
    <el-tab-pane label="Персональный алгоритм">

      <el-row :gutter="20" style="margin-top: 20px; text-align: left;">
        <el-col :span="24">
          <el-input
              v-model="personForm.fio"
              type="text"
              placeholder="Так мы сможем ассоциировать отправленные данные с вам"
              clearable
              class="data-input"
          >
            <template #prepend><div class="ilabel">Для кого расчет </div></template>
          </el-input>
          <el-row>
          <el-input
              v-model="personForm.birthdate"
              type="date"
              clearable
              class="data-input"
          >
            <template #prepend><div class="ilabel">Дата рождения</div></template>
          </el-input>
          </el-row>
          <el-row>
          <el-input
              v-model="personForm.question"
              type="textarea"
              clearable
              maxlength="700"
              show-word-limit
              placeholder = "Сформулируте Ваш вопрос, что вы хотите узнать"
              rows = 2
              class="data-input "
          >
          </el-input>
          </el-row>
        </el-col>
      </el-row>

      <div class="btn-toolbar">
        <div class="left-group">
          <el-button  class="btn calcBtn"  @click="calculatePersonal">
            <el-icon><SwitchFilled /></el-icon>
            <span style="vertical-align: middle"> Рассчитать </span>
            </el-button>

          <el-button  class="btn" plain @click="resetForm" >Очистить</el-button>
        </div>
        <div class="right-group" v-loading="aloading" v-bind:element-loading-text="loadingText">
          <el-button class="btn actionBtn" style="float: right" :disabled=!PMShow  @click="getSnap">
            <el-icon><Promotion /></el-icon>
            <span style="vertical-align: middle"> Отправить расчеты </span>
          </el-button>
          <el-button class="btn " plain   :disabled=!PMShow  @click="doPrint" >Распечатать</el-button>
        </div>
      </div>


      <div style="display: flex; margin-top: 15px" >




      </div>
      <div style=" text-align: right;" >
    </div>
      <el-divider v-if="PMShow" >
        <el-icon color="lightgreen" size="2em"><success-filled /></el-icon>
      </el-divider>
      <MatrixCard id="MTXcard" v-if="personMtx.isComplete"/>




    </el-tab-pane>

    <el-tab-pane disabled label="Алгоритм совместимости (в разработке)">
      <div id="panelX" class="d-none" style="border: solid 2px #66a73b">
        <div class="container mt-5">
          <h2 class="row justify-content-center">
            Алгебра совместимости
          </h2>
        </div>

        <div class="container">
          <h2 class="row justify-content-center" id="age_partner_1">Партнер 1</h2>

          <div id="svgContainerPartner_1">

          </div>
        </div>

        <div class="container">
          <h2 class="row justify-content-center" id="age_partner_2">Партнер 2</h2>

          <div id="svgContainerPartner_2">

          </div>
        </div>



      </div>
    </el-tab-pane>

  </el-tabs>
</template>

<script>
import {inject, reactive} from "vue";
import MatrixCard from "@/components/matrixCard";
import { SuccessFilled, Promotion, SwitchFilled } from '@element-plus/icons-vue'

export default {
  name: "calcPage",
  components: {MatrixCard, SuccessFilled, Promotion, SwitchFilled},
  setup(){
    const inputPersonalDate = reactive({})
    const PMShow = inject('PMShow')
    const personForm = inject('personForm')
    const calculatePersonal = inject('calculatePersonal')
    const resetMTX = inject('resetMTX')
    const getSnap = inject('getSnap')
    const personMtx = inject('personMtx')
    const doPrint = inject('doPrint')
    const aloading = inject('aloading')
    const loadingText = inject('loadingText')


    const resetForm = () => {
      inputPersonalDate.value = ''
      personForm.birthdate = ''
      personForm.fio = ''
      personForm.question = ''
      resetMTX()
    }



    return {PMShow, inputDatePersonal: inputPersonalDate, calculatePersonal, resetForm, personForm, getSnap, personMtx, doPrint,aloading,loadingText}
  }
}
</script>

<style scoped>




.data-input{
  padding-bottom: 1em;
  min-width: 400px;

  height: 4em;

}

@media (max-width: 600px) {
  .data-input {
    min-width: content-box;
    width: 100%;
  }
}
@media (min-width: 600px) {
  .data-input {
    max-width: 700px;
  }
}

.btn-toolbar {
  display: flex;
  flex-flow: row wrap;
  padding-top: 1.5em;
  justify-content: space-between;
}

@media (max-width: 630px) {
  .left-group,
  .right-group {
    display: flex;
    flex-direction: column;
  }
}


.left-group {
  order: 1;
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.right-group {
  order: 2;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}



.ilabel {
  min-width: 110px;
}


</style>