<template>

 <el-container>
   <el-menu

       class="test mainmenu"
       mode="horizontal"
       text-color="#fff"
       default-active="1"
       menu-trigger="hover"
       router
   >
     <el-menu-item index="/"  style="height: unset">
       <el-avatar :size="64" class="avatar" src="./assets/lotus_logo.png"/>

     </el-menu-item>
<!--     <el-menu-item disabled index="/">Алгебра жизни</el-menu-item>-->
     <el-menu-item class="mainmenubar" index="/calc">Калькулятор</el-menu-item>
<!--     <el-menu-item index="/info" disabled>Информация</el-menu-item>-->
   </el-menu>

   <el-main>
     <router-view class="view main-content"></router-view>
   </el-main>

   <el-footer>

   </el-footer>

 </el-container>
</template>

<script>
/* eslint-disable no-unused-vars */

import { provide, reactive, ref } from 'vue'
import dayjs from 'dayjs'
import { ElNotification } from 'element-plus'
import * as htmlToImage from 'html-to-image';


export default {
  name: 'App',
  components: {

  },
  setup(){

    const menuBGColor = '#545c64'
   // const PMShow = ref(false)
    const PMShow = ref(false)
    const loadingText = ref('Отправка рассчетов')
    const aloading = ref(false)
    const personForm = reactive({
      fio:'',
      birthdate:'',
      question:''
    })

    /* ------------------------------------------------------------------------- */

    const initialMtx = reactive({
      age:'',
      personFIO: '',
      personDate: '',

      Axis_0_A:0,
      Axis_0_A1:0,
      Axis_0_A2:0,
      Axis_0_A3:0, //central

      Axis_20_B:0,
      Axis_20_B1:0,
      Axis_20_B2:0,
      Axis_20_B3:0, //central

      Axis_40_C:0,
      Axis_40_C1:0,
      Axis_40_C2:0,


      Axis_60_D:0,
      Axis_60_D1:0,
      Axis_60_D2:0,

      Axis_10_F:0,
      Axis_10_F1:0,
      Axis_10_F2:0,

      Axis_30_G:0,
      Axis_30_G1:0,
      Axis_30_G2:0,

      Axis_50_Y:0,
      Axis_50_Y1:0,
      Axis_50_Y2:0,
      Axis_50_Y3:0, //extended

      Axis_70_K:0,
      Axis_70_K1:0,
      Axis_70_K2:0,

      Axis_CENTR_E:0, //central
      Axis_CENTR_E1:0, //central
      Axis_CENTR_E2:0, //central
      Axis_CENTR_Gen:0,

      Axis_CENTR_E_RESULT:0,
      A3B3_RESULT: 0,

      Summary1:0,
      Summary2:0,
      Summary3:0,

      dotXS_Age_2:0,
      dotXS_Age_3:0,
      dotXS_Age_4:0,
      dotXS_Age_5:0,
      dotXS_Age_6:0,
      dotXS_Age_7:0,
      dotXS_Age_8:0,

      dotXS_Age_12:0,
      dotXS_Age_13:0,
      dotXS_Age_14:0,
      dotXS_Age_15:0,
      dotXS_Age_16:0,
      dotXS_Age_17:0,
      dotXS_Age_18:0,

      dotXS_Age_22:0,
      dotXS_Age_23:0,
      dotXS_Age_24:0,
      dotXS_Age_25:0,
      dotXS_Age_26:0,
      dotXS_Age_27:0,
      dotXS_Age_28:0,

      dotXS_Age_32:0,
      dotXS_Age_33:0,
      dotXS_Age_34:0,
      dotXS_Age_35:0,
      dotXS_Age_36:0,
      dotXS_Age_37:0,
      dotXS_Age_38:0,

      dotXS_Age_42:0,
      dotXS_Age_43:0,
      dotXS_Age_44:0,
      dotXS_Age_45:0,
      dotXS_Age_46:0,
      dotXS_Age_47:0,
      dotXS_Age_48:0,

      dotXS_Age_52:0,
      dotXS_Age_53:0,
      dotXS_Age_54:0,
      dotXS_Age_55:0,
      dotXS_Age_56:0,
      dotXS_Age_57:0,
      dotXS_Age_58:0,

      dotXS_Age_62:0,
      dotXS_Age_63:0,
      dotXS_Age_64:0,
      dotXS_Age_65:0,
      dotXS_Age_66:0,
      dotXS_Age_67:0,
      dotXS_Age_68:0,

      dotXS_Age_72:0,
      dotXS_Age_73:0,
      dotXS_Age_74:0,
      dotXS_Age_75:0,
      dotXS_Age_76:0,
      dotXS_Age_77:0,
      dotXS_Age_78:0,

      stat_sky:0,
      stat_earth:0,
      stat_personalDestinationResult:0,
      stat_paternity:0,
      stat_maternity:0,
      stat_genResult:0,

      stat_commonResult:0,

      TAB_1:0,
      TAB_2:0,
      TAB_3:0,
      TAB_4:0,
      TAB_5:0,
      TAB_6:0,
      TAB_7:0,

      isComplete: false,
    })

    const personMtx = reactive({
      age:'',
      personFIO: '',
      personDate: '',

      Axis_0_A:0,
      Axis_0_A1:0,
      Axis_0_A2:0,
      Axis_0_A3:0, //central

      Axis_20_B:0,
      Axis_20_B1:0,
      Axis_20_B2:0,
      Axis_20_B3:0, //central

      Axis_40_C:0,
      Axis_40_C1:0,
      Axis_40_C2:0,


      Axis_60_D:0,
      Axis_60_D1:0,
      Axis_60_D2:0,

      Axis_10_F:0,
      Axis_10_F1:0,
      Axis_10_F2:0,

      Axis_30_G:0,
      Axis_30_G1:0,
      Axis_30_G2:0,

      Axis_50_Y:0,
      Axis_50_Y1:0,
      Axis_50_Y2:0,
      Axis_50_Y3:0, //extended

      Axis_70_K:0,
      Axis_70_K1:0,
      Axis_70_K2:0,

      Axis_CENTR_E:0, //central
      Axis_CENTR_E1:0, //central
      Axis_CENTR_E2:0, //central
      Axis_CENTR_Gen:0,

      Axis_CENTR_E_RESULT:0,
      A3B3_RESULT: 0,

      Summary1:0,
      Summary2:0,
      Summary3:0,

      dotXS_Age_2:0,
      dotXS_Age_3:0,
      dotXS_Age_4:0,
      dotXS_Age_5:0,
      dotXS_Age_6:0,
      dotXS_Age_7:0,
      dotXS_Age_8:0,

      dotXS_Age_12:0,
      dotXS_Age_13:0,
      dotXS_Age_14:0,
      dotXS_Age_15:0,
      dotXS_Age_16:0,
      dotXS_Age_17:0,
      dotXS_Age_18:0,

      dotXS_Age_22:0,
      dotXS_Age_23:0,
      dotXS_Age_24:0,
      dotXS_Age_25:0,
      dotXS_Age_26:0,
      dotXS_Age_27:0,
      dotXS_Age_28:0,

      dotXS_Age_32:0,
      dotXS_Age_33:0,
      dotXS_Age_34:0,
      dotXS_Age_35:0,
      dotXS_Age_36:0,
      dotXS_Age_37:0,
      dotXS_Age_38:0,

      dotXS_Age_42:0,
      dotXS_Age_43:0,
      dotXS_Age_44:0,
      dotXS_Age_45:0,
      dotXS_Age_46:0,
      dotXS_Age_47:0,
      dotXS_Age_48:0,

      dotXS_Age_52:0,
      dotXS_Age_53:0,
      dotXS_Age_54:0,
      dotXS_Age_55:0,
      dotXS_Age_56:0,
      dotXS_Age_57:0,
      dotXS_Age_58:0,

      dotXS_Age_62:0,
      dotXS_Age_63:0,
      dotXS_Age_64:0,
      dotXS_Age_65:0,
      dotXS_Age_66:0,
      dotXS_Age_67:0,
      dotXS_Age_68:0,

      dotXS_Age_72:0,
      dotXS_Age_73:0,
      dotXS_Age_74:0,
      dotXS_Age_75:0,
      dotXS_Age_76:0,
      dotXS_Age_77:0,
      dotXS_Age_78:0,

      stat_sky:0,
      stat_earth:0,
      stat_personalDestinationResult:0,
      stat_paternity:0,
      stat_maternity:0,
      stat_genResult:0,

      stat_commonResult:0,

      TAB_1:0,
      TAB_2:0,
      TAB_3:0,
      TAB_4:0,
      TAB_5:0,
      TAB_6:0,
      TAB_7:0,

      isComplete: false,

    })





    /* --------- АРХИВ ----------------------------------------------------- */

    // Рассчет совместимости
/*    const calc_x = () => {
      dotLL0_A_x = base22(dotLL0_A[0] + dotLL0_A[1]);
      dotLL20_B_x = base22(dotLL20_B[0] + dotLL20_B[1]);
      dotLL40_C_x = base22(dotLL40_C[0] + dotLL40_C[1]);
      dotLL60_D_x = base22(dotLL60_D[0] + dotLL60_D[1]);

      dotCenter_E_x = base22(dotCenter_E[0] + dotCenter_E[1]);

      dotLL10_F_x = base22(dotLL10_F[0] + dotLL10_F[1]);
      dotLL30_G_x = base22(dotLL30_G[0] + dotLL30_G[1]);
      dotLL50_Y_x = base22(dotLL50_Y[0] + dotLL50_Y[1]);
      dotLL70_K_x = base22(dotLL70_K[0] + dotLL70_K[1]);

      dotMM42_C2_x = base22(dotLL40_C_x + dotCenter_E_x);
      dotMM41_C_x = base22(dotLL40_C_x + dotMM42_C2_x);
      dotMM62_D2_x = base22(dotLL60_D_x + dotCenter_E_x);
      dotMM61_D_x = base22(dotLL60_D_x + dotMM62_D2_x);

      dotSS51_Y_x = base22(dotLL40_C_x + dotLL60_D_x);
      dotSS52_Y2_x = base22(dotMM41_C_x + dotMM61_D_x);
      dotSS53_Y3_x = base22(dotMM42_C2_x + dotMM62_D2_x);
    }*/

    /*    const partnerMtx = reactive({

    })

    const pairMtx = reactive({

      Axis_0_A:0,
      Axis_20_B:0,
      Axis_40_C:0,
      Axis_60_D:0,

      Axis_CENTR_E:0,

      Axis_10_F:0,
      Axis_30_G:0,
      Axis_50_Y:0,
      Axis_70_K:0,

      Axis_40_C1:0,
      Axis_40_C2:0,
      Axis_60_D1:0,
      Axis_60_D2:0,

      Axis_50_Y1:0,
      Axis_50_Y2:0,
      Axis_50_Y3:0,

    })*/

    /* const calculate = (person1, person2) => {

         if (person1 && person2) {
           person1 = dayjs(person1).format('DD.MM.YYYY')
           person2 = dayjs(person2).format('DD.MM.YYYY')

           const personIndex_1 = 1;
           const personIndex_2 = 2;
           const age_partner_1 = document.getElementById("age_partner_1");
           age_partner_1.innerHTML = getAge(person1);

           calc(personIndex_1, person1);
           calc_x();

           svgLayer = setSvgUniversal(svg_1, "svgGroupLayer_1");
           fillSvg(personIndex_1);

           svgLayer = setSvgUniversal(svg_x, "svgGroupLayer_compatibility");
           fillSvgX();

           document.getElementById("svgContainerPartner_1").appendChild(svg_1);

           //////////////////////////
           const age_partner_2 = document.getElementById("age_partner_2");
           age_partner_2.innerHTML = getAge(person2);

           calc(personIndex_2, person2);
           calc_x();

           svgLayer = setSvgUniversal(svg_2, "svgGroupLayer_2");
           fillSvg(personIndex_2);

           document.getElementById("svgContainerPartner_2").appendChild(svg_2);
           ///////////////
           document.querySelector("#panelX").classList.remove("d-none");
         }
         else
         {
           msg('Не правильно указаны даты рождения партнеров')
         }
       }*/
    /* --------- BASE 22 ------------------------------------------------------- */

/*    const _limitValue = (value) => {
      var x;
      var result = parseInt(value);
      while (result > 22) {
        result = (result + "")
            .split("")
            .map((i) => (x += parseInt(i)), (x = 0))
            .reverse()[0];
      }
      return result;
    }*/
    const sum22 = (value) => {
      if (value <= 22) {
        return value;
      }

      let result = 0;
      for (let i = 0; i < value?.length; i++) {
        result += value.charCodeAt(i) - 48;
      }
      if (result > 22) {
        return sum22(result.toString());
      } else {
        return result;
      }
    }
    const base22 = (value) => {
      if (value <= 22) {
        return value;
      } else {
        return sum22(value.toString());
      }

    }
    const getAge = (date) => {
      const arr = date.split(".");
      const value = (new Date() - new Date(arr[2] + "-" + arr[1] + "-" + arr[0] + "T00:00")) / (1000 * 60 * 60 * 24 * 365);
      let res
      let txt
      let count = value % 100
      if (count >=5 && count <=20) {
        txt = ' лет'
      } else {
        count = count % 10;
        if (count == 1) {
          txt = ' год';
        } else if (count >=2 && count <= 4) {
          txt = ' года'
        } else {
          txt = ' лет'
        }
      }
      res = Math.floor(value) + txt
      return res
    }

    const rus_to_latin = (str) => {
      let ru = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya',
        'ъ': 'ie', 'ь': '', 'й': 'i'
      }, n_str = [];

      for ( let i = 0; i < str.length; ++i ) {
        n_str.push(
            ru[ str[i] ]
            || ru[ str[i].toLowerCase() ] == undefined && str[i]
            || ru[ str[i].toLowerCase() ].replace(/^(.)/, function ( match ) { return match.toUpperCase() })
        );
      }
      return n_str.join('');
    }

    const doPrint = () => {
      if (personMtx.isComplete) {
        htmlToImage.toPng(document.getElementById('TelegramContent'), {
          quality: 1,
          pixelRatio: 2,
          type: 'image/png',
          backgroundColor: '#FFF'
        }).then((PNG) => {
          if (PNG) {
            let popup = window.open();
            popup.document.write("<img width='100%'  src="+PNG+">");
            setTimeout(()=> {
              popup.focus();
              popup.print();
              },100)

          }
        });
      }


    }

    const getSnap = () => {

      if (personMtx.isComplete) {
        aloading.value = true
        htmlToImage.toBlob(document.getElementById('TelegramContent'), {
          quality: 1,
          pixelRatio: 2,
          type: 'image/png',
          backgroundColor: '#FFF'
        }).then((blob) => {
          if (blob) {
            let caption = personMtx.personFIO + ' ' + personMtx.personDate + '\r\n' + personForm.question
            let filename = rus_to_latin(personMtx.personFIO).split(' ')[0] + '_' + personMtx.personDate + '.png'
            sendTG(blob, caption, filename)
          }
        });
      }
    }

    const sendTG = async (blob, caption, filename) => {
      try {
        const formData = new FormData();
        formData.append("document", blob, filename);
        formData.append("caption", caption);

        const response = await fetch("./process.php", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        loadingText.value = 'Успешно отправлено'
        setTimeout(() => {
          aloading.value = false;
        }, 30 * 1000);
        msg('Рассчеты успешно отправлены')
      } catch (error) {
        loadingText.value = 'Отправка не удалась'
        msg('Ошибка отправки рассчетов')
        setTimeout(() => {
          aloading.value = false;
        }, 30 * 1000);
       // console.log("Error sending file: ", error.message);
      }
    }


    // Основноый рассчет

    const calculateMtx = (birthDate, personFIO) => {
     // console.log("person", birthDate, personFIO);

      const templateMtx = reactive({
        age:'',
        personFIO: '',
        personDate: '',

        Axis_0_A:0,
        Axis_0_A1:0,
        Axis_0_A2:0,
        Axis_0_A3:0, //central

        Axis_20_B:0,
        Axis_20_B1:0,
        Axis_20_B2:0,
        Axis_20_B3:0, //central

        Axis_40_C:0,
        Axis_40_C1:0,
        Axis_40_C2:0,


        Axis_60_D:0,
        Axis_60_D1:0,
        Axis_60_D2:0,

        Axis_10_F:0,
        Axis_10_F1:0,
        Axis_10_F2:0,

        Axis_30_G:0,
        Axis_30_G1:0,
        Axis_30_G2:0,

        Axis_50_Y:0,
        Axis_50_Y1:0,
        Axis_50_Y2:0,
        Axis_50_Y3:0, //extended

        Axis_70_K:0,
        Axis_70_K1:0,
        Axis_70_K2:0,

        Axis_CENTR_E:0, //central
        Axis_CENTR_E1:0, //central
        Axis_CENTR_E2:0, //central
        Axis_CENTR_Gen:0,

        Axis_CENTR_E_RESULT:0,
        A3B3_RESULT: 0,

        Summary1:0,
        Summary2:0,
        Summary3:0,

        dotXS_Age_2:0,
        dotXS_Age_3:0,
        dotXS_Age_4:0,
        dotXS_Age_5:0,
        dotXS_Age_6:0,
        dotXS_Age_7:0,
        dotXS_Age_8:0,

        dotXS_Age_12:0,
        dotXS_Age_13:0,
        dotXS_Age_14:0,
        dotXS_Age_15:0,
        dotXS_Age_16:0,
        dotXS_Age_17:0,
        dotXS_Age_18:0,

        dotXS_Age_22:0,
        dotXS_Age_23:0,
        dotXS_Age_24:0,
        dotXS_Age_25:0,
        dotXS_Age_26:0,
        dotXS_Age_27:0,
        dotXS_Age_28:0,

        dotXS_Age_32:0,
        dotXS_Age_33:0,
        dotXS_Age_34:0,
        dotXS_Age_35:0,
        dotXS_Age_36:0,
        dotXS_Age_37:0,
        dotXS_Age_38:0,

        dotXS_Age_42:0,
        dotXS_Age_43:0,
        dotXS_Age_44:0,
        dotXS_Age_45:0,
        dotXS_Age_46:0,
        dotXS_Age_47:0,
        dotXS_Age_48:0,

        dotXS_Age_52:0,
        dotXS_Age_53:0,
        dotXS_Age_54:0,
        dotXS_Age_55:0,
        dotXS_Age_56:0,
        dotXS_Age_57:0,
        dotXS_Age_58:0,

        dotXS_Age_62:0,
        dotXS_Age_63:0,
        dotXS_Age_64:0,
        dotXS_Age_65:0,
        dotXS_Age_66:0,
        dotXS_Age_67:0,
        dotXS_Age_68:0,

        dotXS_Age_72:0,
        dotXS_Age_73:0,
        dotXS_Age_74:0,
        dotXS_Age_75:0,
        dotXS_Age_76:0,
        dotXS_Age_77:0,
        dotXS_Age_78:0,

        stat_sky:0,
        stat_earth:0,
        stat_personalDestinationResult:0,
        stat_paternity:0,
        stat_maternity:0,
        stat_genResult:0,

        TAB_1:0,
        TAB_2:0,
        TAB_3:0,
        TAB_4:0,
        TAB_5:0,
        TAB_6:0,
        TAB_7:0,

        stat_commonResult:0,
        isComplete: false

      })

      templateMtx.age = getAge(birthDate)

      const dd = birthDate.split(".")[0];
      const mm = birthDate.split(".")[1];
      const yyyy = birthDate.split(".")[2];

      templateMtx.Axis_0_A = parseInt(sum22(dd));
      templateMtx.Axis_20_B = parseInt(sum22(mm));
      templateMtx.Axis_40_C = parseInt(sum22(yyyy.toString()));

      templateMtx.Axis_60_D = base22(templateMtx.Axis_0_A + templateMtx.Axis_20_B + templateMtx.Axis_40_C);


      templateMtx.Axis_CENTR_E = base22(templateMtx.Axis_0_A + templateMtx.Axis_20_B + templateMtx.Axis_40_C + templateMtx.Axis_60_D);

      templateMtx.Axis_10_F = base22(templateMtx.Axis_0_A + templateMtx.Axis_20_B);
      templateMtx.Axis_30_G = base22(templateMtx.Axis_20_B + templateMtx.Axis_40_C);
      templateMtx.Axis_50_Y = base22(templateMtx.Axis_40_C + templateMtx.Axis_60_D);
      templateMtx.Axis_70_K = base22(templateMtx.Axis_60_D + templateMtx.Axis_0_A);

      templateMtx.Axis_CENTR_Gen = base22(templateMtx.Axis_10_F + templateMtx.Axis_30_G + templateMtx.Axis_50_Y + templateMtx.Axis_70_K);

      templateMtx.Axis_CENTR_E1 = base22(templateMtx.Axis_10_F + templateMtx.Axis_30_G + templateMtx.Axis_50_Y + templateMtx.Axis_70_K);
      templateMtx.Axis_CENTR_E2 = base22(templateMtx.Axis_CENTR_E + templateMtx.Axis_CENTR_E1);

      templateMtx.Axis_0_A1  = base22(templateMtx.Axis_0_A + templateMtx.Axis_CENTR_E)
      templateMtx.Axis_20_B1 = base22(templateMtx.Axis_20_B + templateMtx.Axis_CENTR_E)
      templateMtx.Axis_40_C1 = base22(templateMtx.Axis_40_C + templateMtx.Axis_CENTR_E)
      templateMtx.Axis_60_D1 = base22(templateMtx.Axis_60_D + templateMtx.Axis_CENTR_E)
      templateMtx.Axis_0_A3 = base22(templateMtx.Axis_0_A1 + templateMtx.Axis_CENTR_E);
      templateMtx.Axis_20_B3 = base22(templateMtx.Axis_20_B1 + templateMtx.Axis_CENTR_E);
      templateMtx.Axis_0_A2 = base22(templateMtx.Axis_0_A1 + templateMtx.Axis_0_A);
      templateMtx.Axis_20_B2 = base22(templateMtx.Axis_20_B1 + templateMtx.Axis_20_B);
      templateMtx.Axis_40_C3 = base22(templateMtx.Axis_CENTR_E + templateMtx.Axis_40_C);
      templateMtx.Axis_40_C2 = base22(templateMtx.Axis_40_C3 + templateMtx.Axis_40_C);
      templateMtx.Axis_60_D3 = base22(templateMtx.Axis_CENTR_E + templateMtx.Axis_60_D);
      templateMtx.Axis_60_D2 = base22(templateMtx.Axis_60_D3 + templateMtx.Axis_60_D);

      templateMtx.Axis_10_F1 = base22( templateMtx.Axis_10_F + templateMtx.Axis_CENTR_E1);
      templateMtx.Axis_10_F2  = base22( templateMtx.Axis_10_F +  templateMtx.Axis_10_F1);
      templateMtx.Axis_30_G1 = base22(templateMtx.Axis_30_G + templateMtx.Axis_CENTR_E1)
      templateMtx.Axis_30_G2 = base22(templateMtx.Axis_30_G + templateMtx.Axis_30_G1)
      templateMtx.Axis_50_Y1 = base22(templateMtx.Axis_50_Y + templateMtx.Axis_CENTR_E1);
      templateMtx.Axis_50_Y2 = base22(templateMtx.Axis_50_Y + templateMtx.Axis_50_Y1);
      templateMtx.Axis_50_Y3 = base22(templateMtx.Axis_40_C1 + templateMtx.Axis_60_D1)
      templateMtx.Axis_70_K1 = base22(templateMtx.Axis_70_K + templateMtx.Axis_CENTR_E1)
      templateMtx.Axis_70_K2 = base22(templateMtx.Axis_70_K + templateMtx.Axis_70_K1)



      templateMtx.dotXS_Age_5  = base22( templateMtx.Axis_0_A + templateMtx.Axis_10_F);
      templateMtx.dotXS_Age_3 = base22( templateMtx.Axis_0_A + templateMtx.dotXS_Age_5);
      templateMtx.dotXS_Age_7 = base22(templateMtx.dotXS_Age_5 + templateMtx.Axis_10_F);
      templateMtx.dotXS_Age_2 = base22( templateMtx.Axis_0_A + templateMtx.dotXS_Age_3);
      templateMtx.dotXS_Age_4 = base22(templateMtx.dotXS_Age_3 + templateMtx.dotXS_Age_5);
      templateMtx.dotXS_Age_6 = base22(templateMtx.dotXS_Age_5 + templateMtx.dotXS_Age_7);
      templateMtx.dotXS_Age_8 = base22(templateMtx.dotXS_Age_7 + templateMtx.Axis_10_F);

      templateMtx.dotXS_Age_15 = base22(templateMtx.Axis_10_F + templateMtx.Axis_20_B);
      templateMtx.dotXS_Age_13 = base22(templateMtx.Axis_10_F + templateMtx.dotXS_Age_15);
      templateMtx.dotXS_Age_17 = base22(templateMtx.dotXS_Age_15 + templateMtx.Axis_20_B);
      templateMtx.dotXS_Age_12 = base22(templateMtx.Axis_10_F + templateMtx.dotXS_Age_13);
      templateMtx.dotXS_Age_14 = base22(templateMtx.dotXS_Age_13 + templateMtx.dotXS_Age_15);
      templateMtx.dotXS_Age_16 = base22(templateMtx.dotXS_Age_15 + templateMtx.dotXS_Age_17);
      templateMtx.dotXS_Age_18 = base22(templateMtx.dotXS_Age_17 + templateMtx.Axis_20_B);

      templateMtx.dotXS_Age_25 = base22(templateMtx.Axis_20_B + templateMtx.Axis_30_G);
      templateMtx.dotXS_Age_23 = base22(templateMtx.Axis_20_B + templateMtx.dotXS_Age_25);
      templateMtx.dotXS_Age_27 = base22(templateMtx.dotXS_Age_25 + templateMtx.Axis_30_G);
      templateMtx.dotXS_Age_22 = base22(templateMtx.Axis_20_B + templateMtx.dotXS_Age_23);
      templateMtx.dotXS_Age_24 = base22(templateMtx.dotXS_Age_23 + templateMtx.dotXS_Age_25);
      templateMtx.dotXS_Age_26 = base22(templateMtx.dotXS_Age_25 + templateMtx.dotXS_Age_27);
      templateMtx.dotXS_Age_28 = base22(templateMtx.dotXS_Age_27 + templateMtx.Axis_30_G);

      templateMtx.dotXS_Age_35 = base22(templateMtx.Axis_30_G + templateMtx.Axis_40_C);
      templateMtx.dotXS_Age_33 = base22(templateMtx.Axis_30_G + templateMtx.dotXS_Age_35);
      templateMtx.dotXS_Age_37 = base22(templateMtx.dotXS_Age_35 + templateMtx.Axis_40_C);
      templateMtx.dotXS_Age_32 = base22(templateMtx.Axis_30_G + templateMtx.dotXS_Age_33);
      templateMtx.dotXS_Age_34 = base22(templateMtx.dotXS_Age_33 + templateMtx.dotXS_Age_35);
      templateMtx.dotXS_Age_36 = base22(templateMtx.dotXS_Age_35 + templateMtx.dotXS_Age_37);
      templateMtx.dotXS_Age_38 = base22(templateMtx.dotXS_Age_37 + templateMtx.Axis_40_C);

      templateMtx.dotXS_Age_45 = base22(templateMtx.Axis_40_C + templateMtx.Axis_50_Y);
      templateMtx.dotXS_Age_43 = base22(templateMtx.Axis_40_C + templateMtx.dotXS_Age_45);
      templateMtx.dotXS_Age_47 = base22(templateMtx.dotXS_Age_45 + templateMtx.Axis_50_Y);
      templateMtx.dotXS_Age_42 = base22(templateMtx.Axis_40_C + templateMtx.dotXS_Age_43);
      templateMtx.dotXS_Age_44 = base22(templateMtx.dotXS_Age_43 + templateMtx.dotXS_Age_45);
      templateMtx.dotXS_Age_46 = base22(templateMtx.dotXS_Age_45 + templateMtx.dotXS_Age_47);
      templateMtx.dotXS_Age_48 = base22(templateMtx.dotXS_Age_47 + templateMtx.Axis_50_Y);

      templateMtx.dotXS_Age_55 = base22(templateMtx.Axis_50_Y + templateMtx.Axis_60_D);
      templateMtx.dotXS_Age_53 = base22(templateMtx.Axis_50_Y + templateMtx.dotXS_Age_55);
      templateMtx.dotXS_Age_57 = base22(templateMtx.dotXS_Age_55 + templateMtx.Axis_60_D);
      templateMtx.dotXS_Age_52 = base22(templateMtx.Axis_50_Y + templateMtx.dotXS_Age_53);
      templateMtx.dotXS_Age_54 = base22(templateMtx.dotXS_Age_53 + templateMtx.dotXS_Age_55);
      templateMtx.dotXS_Age_56 = base22(templateMtx.dotXS_Age_55 + templateMtx.dotXS_Age_57);
      templateMtx.dotXS_Age_58 = base22(templateMtx.dotXS_Age_57 + templateMtx.Axis_60_D);

      templateMtx.dotXS_Age_65 = base22(templateMtx.Axis_60_D + templateMtx.Axis_70_K);
      templateMtx.dotXS_Age_63 = base22(templateMtx.Axis_60_D + templateMtx.dotXS_Age_65);
      templateMtx.dotXS_Age_67 = base22(templateMtx.dotXS_Age_65 + templateMtx.Axis_70_K);
      templateMtx.dotXS_Age_62 = base22(templateMtx.Axis_60_D + templateMtx.dotXS_Age_63);
      templateMtx.dotXS_Age_64 = base22(templateMtx.dotXS_Age_63 + templateMtx.dotXS_Age_65);
      templateMtx.dotXS_Age_66 = base22(templateMtx.dotXS_Age_65 + templateMtx.dotXS_Age_67);
      templateMtx.dotXS_Age_68 = base22(templateMtx.dotXS_Age_67 + templateMtx.Axis_70_K);

      templateMtx.dotXS_Age_75 = base22(templateMtx.Axis_70_K + templateMtx.Axis_0_A);
      templateMtx.dotXS_Age_73 = base22(templateMtx.Axis_70_K + templateMtx.dotXS_Age_75);
      templateMtx.dotXS_Age_77 = base22(templateMtx.dotXS_Age_75 + templateMtx.Axis_0_A);
      templateMtx.dotXS_Age_72 = base22(templateMtx.Axis_70_K + templateMtx.dotXS_Age_73);
      templateMtx.dotXS_Age_74 = base22(templateMtx.dotXS_Age_73 + templateMtx.dotXS_Age_75);
      templateMtx.dotXS_Age_76 = base22(templateMtx.dotXS_Age_75 + templateMtx.dotXS_Age_77);
      templateMtx.dotXS_Age_78 = base22(templateMtx.dotXS_Age_77 + templateMtx.Axis_0_A);

      templateMtx.dot_x1 = base22(templateMtx.Axis_50_Y3 + templateMtx.Axis_60_D1)
      templateMtx.dot_x2 = base22(templateMtx.Axis_50_Y3 + templateMtx.Axis_40_C1)

      templateMtx.stat_sky = base22(templateMtx.Axis_20_B + templateMtx.Axis_60_D)
      templateMtx.stat_earth = base22(templateMtx.Axis_0_A + templateMtx.Axis_40_C)
      templateMtx.stat_personalDestinationResult = base22(templateMtx.stat_sky + templateMtx.stat_earth)

      templateMtx.stat_paternity = base22(templateMtx.Axis_30_G + templateMtx.Axis_70_K);
      templateMtx.stat_maternity = base22(templateMtx.Axis_10_F + templateMtx.Axis_50_Y);
      templateMtx.stat_genResult = base22(templateMtx.stat_paternity + templateMtx.stat_maternity);

      templateMtx.Axis_CENTR_E_RESULT = base22(templateMtx.Axis_CENTR_E + templateMtx.Axis_CENTR_E)
      templateMtx.A3B3_RESULT = base22(templateMtx.Axis_0_A3 + templateMtx.Axis_20_B3)

      templateMtx.TAB_1 = base22(templateMtx.Axis_0_A + templateMtx.Axis_20_B)
      templateMtx.TAB_2 = base22(templateMtx.Axis_0_A2 + templateMtx.Axis_20_B2)
      templateMtx.TAB_3 = base22(templateMtx.Axis_0_A1 + templateMtx.Axis_20_B1)
      templateMtx.TAB_4 = base22(templateMtx.Axis_0_A3 + templateMtx.Axis_20_B3)
      templateMtx.TAB_5 = base22(templateMtx.Axis_CENTR_E + templateMtx.Axis_CENTR_E)
      templateMtx.TAB_6 = base22(templateMtx.Axis_40_C1 + templateMtx.Axis_60_D1)
      templateMtx.TAB_7 = base22(templateMtx.Axis_40_C + templateMtx.Axis_60_D)
      templateMtx.Summary1 = base22(templateMtx.Axis_0_A + templateMtx.Axis_0_A2 + templateMtx.Axis_0_A1 + templateMtx.Axis_0_A3 + templateMtx.Axis_CENTR_E + templateMtx.Axis_40_C1 + templateMtx.Axis_40_C)
      templateMtx.Summary2 = base22(templateMtx.Axis_20_B + templateMtx.Axis_20_B2 + templateMtx.Axis_20_B1 + templateMtx.Axis_20_B3 + templateMtx.Axis_CENTR_E + templateMtx.Axis_60_D1 + templateMtx.Axis_60_D)
      templateMtx.Summary3 = base22(templateMtx.Summary1 + templateMtx.Summary2)

      templateMtx.stat_commonResult = base22(templateMtx.stat_personalDestinationResult + templateMtx.stat_genResult)

      templateMtx.personDate = birthDate
      templateMtx.personFIO = personFIO
      templateMtx.isComplete = true
      //console.log(templateMtx)

      return templateMtx
    }

    const calculatePersonal = () => {

      // personDate = '01.01.2009' // TODO: Debug

      if (personForm.fio) {
        if(personForm.question) {
          if (personForm.birthdate) {
            let personDate = dayjs(personForm.birthdate).format('DD.MM.YYYY')
            let tmp = calculateMtx(personDate, personForm.fio)
            Object.assign(personMtx, tmp)
            if (personMtx.isComplete) {
              PMShow.value = true
            }
          } else {
            msg('Укажите правильную дату расчета')
          }
        } else {
          msg('Укажите вопрос, который вас интересует')
        }
      } else {
        msg('Укажите для кого расчет')
      }
    }

    const resetMTX = () => {

      Object.assign(personMtx, initialMtx)
      PMShow.value = false
    }


    const msg = (text) => {
      ElNotification({message:text, position: 'top-left', type:'warning'})
    }

    provide('resetMTX', resetMTX)
    provide('loadingText', loadingText)
    provide('aloading', aloading)
    provide('getSnap', getSnap)
    provide('personMtx', personMtx)
    provide('PMShow', PMShow)
    provide('calculatePersonal', calculatePersonal)
    provide('msg', msg)
    provide('doPrint', doPrint)
    provide('personForm', personForm)

    return{ menuBGColor}
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 4px;
}

.avatar{
  --el-avatar-bg-color: #FFF;
}

.mainmenu {
  height: 90px;
}

.mainmenubar {
  font-size: 1.6em;
}


.calcBtn {
  color: var(--1st);
  --el-button-hover-bg-color: var(--4th) !important;
  --el-button-hover-text-color: var(--2nd) !important;
}

.actionBtn {
  --el-button-hover-bg-color: #279ed9 !important;
  --el-button-hover-text-color: var(--2nd);

}

.btn {
  padding: 20px 20px 20px 20px;
  background-color: var(--2nd);
  --el-button-active-border-color: #941212 !important;
  --el-button-hover-border-color: #941212 !important;
  --el-button-hover-background-color: var(--2nd) !important;
}

@media (max-width: 630px) {
  .btn {
    display: flex;
    margin: 3% 3% 3% 3% !important;
    padding: 20px 20px 20px 20px !important;
    flex-direction: column;
  }
}

.test {
  --1st: #B06256FF;
  --2nd: #FFF;
  --3rd: #BDCDD6;
  --4th: #F7988BFF;

  /* --el-bg-color-overlay: #F7F5EB;*/
  --el-menu-text-color: #745d5d !important;
  --el-menu-hover-text-color: #574444 !important;

  --el-color-primary: var(--1st);
  --el-button-bg-color:var(--1st);
  --el-button-text-color: var(--4th);
  --el-button-hover-bg-color: var(--2nd);
  --el-button-hover-text-color: var(--4th);

  --el-menu-bg-color: #fff;
  --el-menu-hover-bg-color: var(--2nd);
/*  --el-menu-hover-text-color: var(--4th);*/
  --el-menu-active-color: var(--4th);

}

</style>
