<template>
  <body >
  <div id="root">
  <div class="App">
<!--    <div class="user-form">
      <input placeholder="дата.месяц.год" value="13.05.1990" style="text-align: center; font-size: 24px;">
    </div>-->
    <div id="TelegramContent" style="display: block;">
      <section class="section-with-diagram section pb-0">
        <div class="h3 section-with-diagram__title text-center">Персональный расчет</div>
        <div class="h3">{{personMtx.personFIO}} {{personMtx.personDate}} ({{personMtx.age}})</div>
        <div  class="section-with-diagram__top-content">
          <div class="section-with-diagram__col-with-table ">
            <div class="h5 section-with-diagram__table-title">Карта здоровья</div>

            <div >

                <div class="custom-table-responsive">

                  <table class="custom-table section-with-diagram__table health-table">
                    <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Чакра</th>
                      <th scope="col">Физика</th>
                      <th scope="col">Энергия</th>
                      <th scope="col">Эмоции</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr class="-purple-row " data-row-number="1" >
                      <th class="numbers" scope="row">1</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Сахасрара</span></div>
                      </td>
                      <td class="h5">{{personMtx.Axis_0_A}}</td>
                      <td class="h5">{{personMtx.Axis_20_B}}</td>
                      <td class="h5">{{personMtx.TAB_1}}</td>
                    </tr>

                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr />
                    <tr class="-blue-row" data-row-number="2" >
                      <th class="numbers" scope="row">2</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Аджна</span></div>
                      </td>
                      <td class="h5" >{{personMtx.Axis_0_A2}}</td>
                      <td class="h5">{{personMtx.Axis_20_B2}}</td>
                      <td class="h5">{{personMtx.TAB_2}}</td>
                    </tr>

                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr class="-cyan-row" data-row-number="3" >
                      <th class="numbers" scope="row">3</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Вишудха</span></div>
                      </td>
                      <td class="h5">{{personMtx.Axis_0_A1}}</td>
                      <td class="h5">{{personMtx.Axis_20_B1}}</td>
                      <td class="h5">{{personMtx.TAB_3}}</td>
                    </tr>

                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr class="-green-row" data-row-number="4" >
                      <th class="numbers" scope="row">4</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Анахата</span></div>
                      </td>
                      <td class="h5">{{personMtx.Axis_0_A3}}</td>
                      <td class="h5">{{personMtx.Axis_20_B3}}</td>
                      <td class="h5">{{personMtx.TAB_4}}</td>
                    </tr>

                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr class="-yellow-row" data-row-number="5" >
                      <th class="numbers" scope="row">5</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Манипура</span></div>
                      </td>
                      <td class="h5">{{personMtx.Axis_CENTR_E}}</td>
                      <td class="h5">{{personMtx.Axis_CENTR_E}}</td>
                      <td class="h5" >{{personMtx.TAB_5}}</td>
                    </tr>

                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr class="-orange-row" data-row-number="6" >
                      <th class="numbers" scope="row">6</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Свадхистана</span></div>
                      </td>
                      <td class="h5">{{personMtx.Axis_40_C1}}</td>
                      <td class="h5">{{personMtx.Axis_60_D1}}</td>
                      <td class="h5" >{{personMtx.TAB_6}}</td>
                    </tr>

                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr class="-red-row" data-row-number="7" >
                      <th class="numbers" scope="row">7</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Муладхара</span></div>
                      </td>
                      <td class="h5">{{personMtx.Axis_40_C}}</td>
                      <td class="h5">{{personMtx.Axis_60_D}}</td>
                      <td class="h5">{{personMtx.TAB_7}}</td>
                    </tr>

                    <tr class="spacer"><td colspan="100"></td></tr>

                    <tr class="-gray-row" data-row-number="8" >
                      <th scope="row">8</th>
                      <td>
                        <div class="h5 table-title-with-prompt"><span >Итог</span></div>
<!--                        <div class="h6 table-sub-title">Общее энергополе</div>-->
                      </td>
                      <td class="h5">{{personMtx.Summary1}}</td>
                      <td class="h5">{{personMtx.Summary2}}</td>
                      <td class="h5">{{personMtx.Summary3}}</td>
                    </tr>
                    <tr class="spacer"><td colspan="100"></td></tr>

                    </tbody>
                  </table>
                </div>
              </div>

            <ul class="diagram-values-list">
              <li class="diagram-values-list__item">
                <div class="diagram-values-item-horizontal">
                  <div class="h6 diagram-values-item-horizontal__title">Родовые программы по мужской линии:</div>
                  <ul class="diagram-values-item-horizontal__values round-values-list">
                    <li class="round-values-list__item">
                      <div class="diagram-value  -active">{{personMtx.Axis_10_F}}</div>
                    </li>
                    <li class="round-values-list__item">
                      <div class="diagram-value  -active">{{personMtx.Axis_50_Y}}</div>
                    </li>
                    <li class="round-values-list__item">
                      <div class="diagram-value  -active">{{personMtx.stat_paternity}}</div>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="diagram-values-list__item">
                <div class="diagram-values-item-horizontal">
                  <div class="h6 diagram-values-item-horizontal__title">Родовые программы по женской линии:</div>
                  <ul class="diagram-values-item-horizontal__values round-values-list">
                    <li class="round-values-list__item">
                      <div class="diagram-value  -active">{{personMtx.Axis_30_G}}</div>
                    </li>
                    <li class="round-values-list__item">
                      <div class="diagram-value  -active">{{personMtx.Axis_70_K}}</div>
                    </li>
                    <li class="round-values-list__item">
                      <div class="diagram-value  -active">{{personMtx.stat_maternity}}</div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="section-with-diagram__col-with-diagram">
            <div class="section-with-diagram__matrix-diagram">
              <div class="matrix-diagram">
                <div id="svg" class="matrix-diagram__svg"><img id="svgA" src="../css/mtx.svg" alt="diagram"></div>
                <!--Main circles-->
                <div class="matrix-diagram__circle -size-lg -position-a -purple -active">{{personMtx.Axis_0_A}}</div>
                <div class="matrix-diagram__circle -size-lg -position-b -purple -active">{{personMtx.Axis_20_B}}</div>
                <div class="matrix-diagram__circle -size-lg -position-c -red -active">{{personMtx.Axis_40_C}}</div>
                <div class="matrix-diagram__circle -size-lg -position-d -red -active">{{personMtx.Axis_60_D}}</div>
                <div class="matrix-diagram__circle -size-lg -position-e -yellow -active">{{personMtx.Axis_CENTR_E}}</div>
                <div class="matrix-diagram__circle -size-lg -position-f -white -active">{{personMtx.Axis_10_F}}</div>
                <div class="matrix-diagram__circle -size-lg -position-g -white -active">{{personMtx.Axis_30_G}}</div>
                <div class="matrix-diagram__circle -size-lg -position-y -white -active">{{personMtx.Axis_50_Y}}</div>
                <div class="matrix-diagram__circle -size-lg -position-k -white -active">{{personMtx.Axis_70_K}}</div>

                <div class="matrix-diagram__circle -size-md -position-a2 -blue -active">{{personMtx.Axis_0_A2}}</div>
                <div class="matrix-diagram__circle -size-md -position-b2 -blue -active">{{personMtx.Axis_20_B2}}</div>
                <div class="matrix-diagram__circle -size-md -position-c2 -white -active">{{personMtx.Axis_40_C2}}</div>
                <div class="matrix-diagram__circle -size-md -position-d2 -white -active">{{personMtx.Axis_60_D2}}</div>
                <div class="matrix-diagram__circle -size-md -position-e1 -white -active">{{personMtx.Axis_CENTR_E1}}</div>
                <div class="matrix-diagram__circle -size-md -position-p2 -white -active">{{personMtx.Axis_30_G2}}</div>
                <div class="matrix-diagram__circle -size-md -position-s2 -white -active">{{personMtx.Axis_10_F2}}</div>
                <div class="matrix-diagram__circle -size-md -position-s3 -white -active">{{personMtx.Axis_50_Y2}}</div>
                <div class="matrix-diagram__circle -size-md -position-p4 -white -active">{{personMtx.Axis_70_K2}}</div>
                <div class="matrix-diagram__circle -size-sm -position-a1 -cyan -active">{{personMtx.Axis_0_A1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-b1 -cyan -active">{{personMtx.Axis_20_B1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-a3 -green -active">{{personMtx.Axis_0_A3}}</div>
                <div class="matrix-diagram__circle -size-sm -position-b3 -green -active">{{personMtx.Axis_20_B3}}</div>
                <div class="matrix-diagram__circle -size-sm -position-c1 -orange -active">{{personMtx.Axis_40_C1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-d1 -orange -active">{{personMtx.Axis_60_D1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-e2 -white -active">{{personMtx.Axis_CENTR_E2}}</div>
                <div class="matrix-diagram__circle -size-sm -position-p1 -white -active">{{personMtx.Axis_30_G1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-p3 -white -active">{{personMtx.Axis_70_K1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-s1 -white -active">{{personMtx.Axis_10_F1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-s4 -white -active">{{personMtx.Axis_50_Y1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-x -white -active">{{personMtx.Axis_50_Y3}}</div>
                <div class="matrix-diagram__circle -size-sm -position-x1 -white -active">{{personMtx.dot_x1}}</div>
                <div class="matrix-diagram__circle -size-sm -position-x2 -white -active">{{personMtx.dot_x2}}</div>
                <div class="matrix-diagram__number -position-b8">{{personMtx.dotXS_Age_22}}</div>
                <div class="matrix-diagram__number -position-b7">{{personMtx.dotXS_Age_23}}</div>
                <div class="matrix-diagram__number -position-k2">{{personMtx.dotXS_Age_24}}</div>
                <div class="matrix-diagram__number -position-k1">{{personMtx.dotXS_Age_25}}</div>
                <div class="matrix-diagram__number -position-k4">{{personMtx.dotXS_Age_26}}</div>
                <div class="matrix-diagram__number -position-k3">{{personMtx.dotXS_Age_27}}</div>
                <div class="matrix-diagram__number -position-k5">{{personMtx.dotXS_Age_28}}</div>
                <div class="matrix-diagram__number -position-k8">{{personMtx.dotXS_Age_32}}</div>
                <div class="matrix-diagram__number -position-k7">{{personMtx.dotXS_Age_33}}</div>
                <div class="matrix-diagram__number -position-k6">{{personMtx.dotXS_Age_34}}</div>
                <div class="matrix-diagram__number -position-c4">{{personMtx.dotXS_Age_35}}</div>
                <div class="matrix-diagram__number -position-c6">{{personMtx.dotXS_Age_36}}</div>
                <div class="matrix-diagram__number -position-c5">{{personMtx.dotXS_Age_37}}</div>
                <div class="matrix-diagram__number -position-c7">{{personMtx.dotXS_Age_38}}</div>
                <div class="matrix-diagram__number -position-y3">{{personMtx.dotXS_Age_42}}</div>
                <div class="matrix-diagram__number -position-y2">{{personMtx.dotXS_Age_43}}</div>
                <div class="matrix-diagram__number -position-y4">{{personMtx.dotXS_Age_44}}</div>
                <div class="matrix-diagram__number -position-y1">{{personMtx.dotXS_Age_45}}</div>
                <div class="matrix-diagram__number -position-y6">{{personMtx.dotXS_Age_46}}</div>
                <div class="matrix-diagram__number -position-y5">{{personMtx.dotXS_Age_47}}</div>
                <div class="matrix-diagram__number -position-y7">{{personMtx.dotXS_Age_48}}</div>
                <div class="matrix-diagram__number -position-d6">{{personMtx.dotXS_Age_52}}</div>
                <div class="matrix-diagram__number -position-y8">{{personMtx.dotXS_Age_53}}</div>
                <div class="matrix-diagram__number -position-d5">{{personMtx.dotXS_Age_54}}</div>
                <div class="matrix-diagram__number -position-d4">{{personMtx.dotXS_Age_55}}</div>
                <div class="matrix-diagram__number -position-d8">{{personMtx.dotXS_Age_56}}</div>
                <div class="matrix-diagram__number -position-d7">{{personMtx.dotXS_Age_57}}</div>
                <div class="matrix-diagram__number -position-d9">{{personMtx.dotXS_Age_58}}</div>
                <div class="matrix-diagram__number -position-g3">{{personMtx.dotXS_Age_62}}</div>
                <div class="matrix-diagram__number -position-g2">{{personMtx.dotXS_Age_63}}</div>
                <div class="matrix-diagram__number -position-g4">{{personMtx.dotXS_Age_64}}</div>
                <div class="matrix-diagram__number -position-g1">{{personMtx.dotXS_Age_65}}</div>
                <div class="matrix-diagram__number -position-g7">{{personMtx.dotXS_Age_66}}</div>
                <div class="matrix-diagram__number -position-g5">{{personMtx.dotXS_Age_67}}</div>
                <div class="matrix-diagram__number -position-g6">{{personMtx.dotXS_Age_68}}</div>
                <div class="matrix-diagram__number -position-t3">{{personMtx.dotXS_Age_72}}</div>
                <div class="matrix-diagram__number -position-t2">{{personMtx.dotXS_Age_73}}</div>
                <div class="matrix-diagram__number -position-t4">{{personMtx.dotXS_Age_74}}</div>
                <div class="matrix-diagram__number -position-t1">{{personMtx.dotXS_Age_75}}</div>
                <div class="matrix-diagram__number -position-t6">{{personMtx.dotXS_Age_76}}</div>
                <div class="matrix-diagram__number -position-t5">{{personMtx.dotXS_Age_77}}</div>
                <div class="matrix-diagram__number -position-t7">{{personMtx.dotXS_Age_78}}</div>
                <div class="matrix-diagram__number -position-a5">{{personMtx.dotXS_Age_2}}</div>
                <div class="matrix-diagram__number -position-a4">{{personMtx.dotXS_Age_3}}</div>
                <div class="matrix-diagram__number -position-a6">{{personMtx.dotXS_Age_4}}</div>
                <div class="matrix-diagram__number -position-f1">{{personMtx.dotXS_Age_5}}</div>
                <div class="matrix-diagram__number -position-f3">{{personMtx.dotXS_Age_6}}</div>
                <div class="matrix-diagram__number -position-f2">{{personMtx.dotXS_Age_7}}</div>
                <div class="matrix-diagram__number -position-f4">{{personMtx.dotXS_Age_8}}</div>
                <div class="matrix-diagram__number -position-f7">{{personMtx.dotXS_Age_12}}</div>
                <div class="matrix-diagram__number -position-f6">{{personMtx.dotXS_Age_13}}</div>
                <div class="matrix-diagram__number -position-f8">{{personMtx.dotXS_Age_14}}</div>
                <div class="matrix-diagram__number -position-f5">{{personMtx.dotXS_Age_15}}</div>
                <div class="matrix-diagram__number -position-b5">{{personMtx.dotXS_Age_16}}</div>
                <div class="matrix-diagram__number -position-b4">{{personMtx.dotXS_Age_17}}</div>
                <div class="matrix-diagram__number -position-b6">{{personMtx.dotXS_Age_18}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-with-diagram__bottom-values-row row">
          <div class="col">
            <div class="diagram-values-item">
              <div class="h6 diagram-values-item__title">Личное предназначение</div>
              <div class="diagram-values-item__description text-block"><p>Соединение мужского и женского. Выстраивание
                взаимоотношений. Способности, навыки, умения.</p></div>
              <div class="small-diagram-values">
                <div class="small-diagram-values__titles-block">
                  <div class="h6 small-diagram-values__title">Небо</div>
                  <div class="h6 small-diagram-values__title">Земля</div>
                </div>
                <div class="small-diagram-values__values-block">
                  <div class="small-diagram-values__path-img"><img
                      src="../css/path-lines.svg" alt="route"></div>
                  <div class="small-diagram-values__value  -top-left diagram-value -active">
                    {{personMtx.stat_sky}}
                  </div>
                  <div
                      class="small-diagram-values__value  -bottom-left diagram-value -active">
                    {{personMtx.stat_earth}}
                  </div>
                  <div class="small-diagram-values__value  -center diagram-value -active">
                    {{personMtx.stat_personalDestinationResult}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="diagram-values-item">
              <div class="h6 diagram-values-item__title">Социальное предназначение</div>
              <div class="diagram-values-item__description text-block"><p>Социальная и родовая системы. Результаты и
                признания в социуме.</p></div>
              <div class="small-diagram-values">
                <div class="small-diagram-values__titles-block">
                  <div class="h6 small-diagram-values__title">Муж</div>
                  <div class="h6 small-diagram-values__title">Жен</div>
                </div>
                <div class="small-diagram-values__values-block">
                  <div class="small-diagram-values__path-img"><img
                      src="../css/path-lines.svg" alt="route"></div>
                  <div class="small-diagram-values__value  -top-left diagram-value -active">
                    {{personMtx.stat_paternity}}
                  </div>
                  <div
                      class="small-diagram-values__value  -bottom-left diagram-value -active">
                    {{personMtx.stat_maternity}}
                  </div>
                  <div class="small-diagram-values__value  -center diagram-value -active">
                    {{personMtx.stat_genResult}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="diagram-values-item">
              <div class="h6 diagram-values-item__title">Духовное предназначение</div>
              <div class="diagram-values-item__description text-block"><p>Духовный зачет. Кто я для бога? Где
                божественное во мне?</p></div>
              <div class="small-diagram-values">
                <div class="diagram-value  -active">{{personMtx.stat_commonResult}}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
  </div>
  </div>
  </body>

</template>

<script>
import {inject} from "vue";



export default {
  name: "matrixCard",

  setup(){
    const personMtx = inject('personMtx')

    // eslint-disable-next-line no-unused-vars


    return { personMtx}
  }

}
</script>

<style >

.numbers {
  color: #1e232a !important;
}

#app {
  min-width: 480px;
}

table {
  border-collapse: collapse
}

.section-with-diagram {

}
.section {
  padding: 1em 0
}
@media only screen and (min-width: 768px) {
  .section {
    padding: 2em 0
  }
}
.pb-0 {
  padding-bottom: 0 !important
}
.h2, h2 {
  font-size: 2.25rem
}
@media only screen and (max-width: 991px) {
  .h2, h2 {
    font-size: 26px
  }
}
.h2, h2 {
  margin-bottom: 40px
}
.h3, h3 {
  margin-bottom: 2em;
  font-weight: 600;
  font-size: 1.5em;
}
@media only screen and (min-width: 992px) {
  .h2, h2 {
    margin-bottom: 60px;

  }
}
@media only screen and (min-width: 992px) {
  .section-with-diagram__title {
    margin-bottom: 5px
  }
}
.section-with-diagram__title {
  margin-bottom: 20px
}
@media only screen and (min-width: 576px) {
  .section-with-diagram__title {
    margin-bottom: 20px
  }
}
.text-center {
  text-align: center !important
}

.section-with-diagram__top-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 40px
}
@media only screen and (min-width: 992px) {
  .section-with-diagram__top-content {
    flex-direction: row
  }
}
.section-with-diagram__col-with-table {
  order: 2
}
@media only screen and (min-width: 992px) {
  .section-with-diagram__col-with-table {
    flex: 0 0 500px;
    /*max-width: 500px;*/
    order: 1
  }
}

.h5, h5 {
  font-size: 1.5rem
}
.h5, h5 {
  font-weight: 600;
  margin-bottom: 20px
}

.h6, h6 {
  font-size: 1.0625rem
}
.h6, h6 {
  color: #000;
  font-family: inherit;
  font-weight: 400;
  margin-bottom: 5px
}
@media only screen and (max-width: 991px) {
  .h6, h6 {
    font-size: 15px
  }
}

@media only screen and (max-width: 767px) {
  .h5, h5 {
    font-size: 1em
  }
}
@media only screen and (min-width: 576px) {
  .section-with-diagram__table-title {
    margin-bottom: 0.5em
  }
}
.section-with-diagram__table-title {
  margin-bottom: 0.3em
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
  /*width: 100%*/
}

.table-responsive > .table-bordered {
  border: 0
}



.table td, .table th {
  border-top: 0 solid #222831;
  padding: 5px 15px;
 /* vertical-align: top*/
}

.table thead th {
  border-bottom: 0 solid #222831;
  vertical-align: bottom
}

.table tbody + tbody {
  border-top: 0 solid #222831
}

.table-sm td, .table-sm th {
  padding: .3rem
}

.health-table {
  font-size: 12px;
  width: 100%;

}
@media only screen and (min-width: 400px) {
  .health-table {
    font-size: 14px
  }
}
@media only screen and (min-width: 576px) {
  .health-table {
    /* border-right: 1px solid #222831;*/
    font-size: 16px;
    margin-left: 0;
    margin-right: 0;
    width: 100%

  }
}


.health-table thead tr th:first-child {
 /* background-color: #c6c8d4;*/
 /* padding-left: 10px;*/
}

.health-table tbody tr {
  transition: background-color .3s
}

.health-table tbody tr:nth-child(2n) {
  background-color: #f4f4f6
}

.health-table tbody tr.-purple-row th:first-child {
  background-color: #8a46a3
}

.health-table tbody tr.-purple-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: rgba(138, 70, 163, .5)
}

.health-table tbody tr.-purple-row.-active {
  background-color: rgba(138, 70, 163, .2)
}

.health-table tbody tr.-blue-row th:first-child {
  background-color: #526dfa
}

.health-table tbody tr.-blue-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: #526dfa
}

.health-table tbody tr.-blue-row.-active {
  background-color: rgba(82, 109, 250, .2)
}

.health-table tbody tr.-cyan-row th:first-child {
  background-color: #45cfe1
}

.health-table tbody tr.-cyan-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: #45cfe1
}

.health-table tbody tr.-cyan-row.-active {
  background-color: rgba(69, 207, 225, .2)
}

.health-table tbody tr.-green-row th:first-child {
  background-color: #afde61
}

.health-table tbody tr.-green-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: #afde61
}

.health-table tbody tr.-green-row.-active {
  background-color: rgba(175, 222, 97, .2)
}

.health-table tbody tr.-yellow-row th:first-child {
  background-color: #ffed47
}

.health-table tbody tr.-yellow-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: #ffc700
}

.health-table tbody tr.-yellow-row.-active {
  background-color: rgba(255, 237, 71, .2)
}

.health-table tbody tr.-orange-row th:first-child {
  background-color: #ffae34
}

.health-table tbody tr.-orange-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: #ffae34
}

.health-table tbody tr.-orange-row.-active {
  background-color: rgba(255, 174, 52, .2)
}

.health-table tbody tr.-red-row th:first-child {
  background-color: #ef4141
}

.health-table tbody tr.-red-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: #ef4141
}

.health-table tbody tr.-red-row.-active {
  background-color: rgba(239, 65, 65, .2)
}

.health-table tbody tr.-gray-row th:first-child {
  background-color: #d7d9da
}

.health-table tbody tr.-gray-row .table-title-with-prompt .table-title-with-prompt__icon {
  color: #a0a2af
}

.health-table tbody tr.-gray-row.-active {
  background-color: hsla(232, 9%, 66%, .2)
}

.health-table tbody tr > :not(:nth-child(2)), .health-table thead tr > :not(:nth-child(2)) {
  text-align: center
}

.health-table tbody tr th:first-child, .health-table thead tr th:first-child {
  min-width: 15px;
  padding-left: 5px;
  padding-right: 5px
}
.table-title-with-prompt {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-bottom: 4px
}
@media only screen and (min-width: 576px) {
  .table-title-with-prompt {
    font-size: 16px
  }
}
@media only screen and (min-width: 576px) {
  .table-sub-title {
    font-size: 14px;
    text-align: left;
  }
}
.table-sub-title {
  font-size: 12px;
  margin-bottom: 0;
  opacity: .7;
  text-align: left;
}

.diagram-values-list {
  max-width: 470px;
  list-style-type: none;
  padding-inline-start: 0;

}

.print-diagram-wrap .section-with-diagram__bottom-values-row [class^=col] {
  flex-basis: 0 !important
}

.print-diagram-wrap .section-with-diagram__bottom-values-row [class^=col]:first-child {
  flex: 0 0 390px !important;
  max-width: 390px !important
}

.print-diagram-wrap .section-with-diagram__bottom-values-row [class^=col]:first-child .diagram-values-item {
  max-width: 265px !important
}

.print-diagram-wrap .section-with-diagram__bottom-values-row [class^=col]:nth-child(2) {
  flex: 0 0 335px !important;
  max-width: 335px !important
}

.print-diagram-wrap .section-with-diagram__bottom-values-row [class^=col]:nth-child(2) .diagram-values-item {
  max-width: 200px !important
}

.print-diagram-wrap .section-with-diagram__bottom-values-row [class^=col]:last-child {
  flex: 0 0 242px !important;
  max-width: 242px !important
}

.print-diagram-wrap .section-with-diagram__bottom-values-row [class^=col]:last-child .diagram-values-item {
  margin-bottom: 0 !important
}
.row {
  display: flex;
}

@media screen and (max-width: 768px) {
  .row {
    display: block;
    font-size: 1em;
  }
}


@media only screen and (max-width: 767px) {
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-left: 30px;
    padding-right: 30px
  }

  .row {
    margin-left: -30px;
    margin-right: -30px
  }
}
.row .col, .row [class^=col-] {
  position: inherit
}
@media only screen and (max-width: 767px) {
  .row .col, .row [class^=col-] {
    padding-left: 30px;
    padding-right: 30px
  }
}
.diagram-values-item {
  margin-right: 40px;
  margin-bottom: 50px
}
.diagram-values-item__title {
  font-weight: 500;
  margin-bottom: 10px
}
.diagram-values-item__description {
  font-size: 16px;
  line-height: 1.375;
  margin-bottom: 20px;
  overflow: hidden;
  max-width: 460px;
  display: inline;
 /* max-height: значение;*/

}
.small-diagram-values {
  align-items: center;
  display: inline-flex;
  min-height: 80px;

}
.small-diagram-values__titles-block {
  min-width: 70px;
  padding-right: 25px
}
@media only screen and (min-width: 768px) {
  .small-diagram-values__titles-block {
    min-width: auto
  }
}
.small-diagram-values__title {
  margin-bottom: 20px
}
.small-diagram-values__title:last-child {
  margin-bottom: 0
}
.small-diagram-values__values-block {
  height: 80px;
  position: relative;
  width: 130px
}
.small-diagram-values__path-img {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 38px
}
.small-diagram-values__value.-top-left {
  left: 0;
  top: 0
}
.small-diagram-values__value {
  position: absolute
}
.small-diagram-values__value.-top-left {
  left: 0;
  top: 0
}
.diagram-value {
  align-items: center;
  background-color: #cacace;
  border: 2px solid transparent;
  border-radius: 50%;
  display: flex;
  font-size: 17px;
  height: 35px;
  justify-content: center;
  transition: background-color .3s, border-color .3s;
  width: 35px
}
.diagram-value.-active {
  background-color: #fff;
  border-color: #222831
}

.diagram-values-item-horizontal {
  align-items: center;
  display: flex;
  justify-content: space-between
}
.diagram-values-item-horizontal__title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
/*  padding-right: 30px*/
}
.diagram-values-item-horizontal__values {
  margin-right: -2px
}
.round-values-list {
  align-items: center;
  display: flex;
  padding-left: 0;
}
.round-values-list__item {
  list-style-type: none;
  margin: 2px;
}
.section-with-diagram__matrix-diagram {
 /* margin-left: -30px;
  margin-right: -30px*/
}

@media only screen and (min-width: 576px) {
  .section-with-diagram__matrix-diagram {
    margin-left: 0;
    margin-right: 0;
    font-size: 1em;
  }
}
.matrix-diagram {
  font-size: 4px;
  margin: 0 auto;
  max-width: 872px;
  max-height: 872px;
  position: relative
}

@media only screen and (min-width: 400px) {
  .matrix-diagram {
    font-size: 5px
  }
}

@media only screen and (min-width: 576px) {
  .matrix-diagram {
    font-size: 0.3em;
  }
}
@media only screen and (min-width: 650px) {
  .matrix-diagram {
    font-size: 0.4em;
  }
}

@media only screen and (min-width: 1260px) {
  .matrix-diagram {
    font-size: 8px

  }
}

.section-with-diagram__col-with-diagram {
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 20px;
  max-width: 100%;
  order: 1;
  width: 100%;

}
@media only screen and (min-width: 576px) {
  .section-with-diagram__col-with-diagram {
    margin-bottom: 45px
  }
}
@media only screen and (min-width: 992px) {
  .section-with-diagram__col-with-diagram {
    margin-bottom: 0;
    order: 2;
    padding-left: 30px;

  }
}
@media only screen and (min-width: 1260px) {
  .section-with-diagram__col-with-diagram {
    padding-left: 30px
  }

}@media only screen and (min-width: 1900px) {
  .matrix-diagram {
    margin: 0 auto;
    max-width: 1000px;
    max-height: 1000px;
    position: relative;
  }
}


@media only screen and (min-width: 1260px) {
  .section-with-diagram__col-with-table {
    padding-left: 30px;
    margin-left: 2em;

  }
}
@media only screen and (min-width: 1600px) {
  .section-with-diagram__col-with-table {
    padding-left: 30px;
    margin-left: 8em;
    min-width: 500px;
  }
}

@media only screen and (min-width: 1900px) {
  .section-with-diagram__col-with-table {
    padding-left: 30px;
    margin-left: 10em;
    min-width: 20em;
  }
}

@media only screen and (min-width: 2120px) {
  .section-with-diagram__col-with-table {
    padding-left: 30px;
    margin-left: 13em;
    min-width: 670px;
  }
}


.matrix-diagram__circle {
  align-items: center;
  background-color: #cacace;
  border: 2px solid transparent;
  border-radius: 50%;
  color: #a3a3a6;
  display: flex;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  position: absolute;
  transition: color .3s, background-color .3s, border-color .3s;
  z-index: 5
}

.matrix-diagram__circle.-position-a {
  left: 6%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-a2 {
  left: 13%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-a1 {
  left: 19.4%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-a3 {
  left: 32.4%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-b {
  left: 50%;
  top: 6.1%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.matrix-diagram__circle.-position-b2 {
  left: 50%;
  top: 13.1%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.matrix-diagram__circle.-position-b1 {
  left: 50%;
  top: 19.5%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.matrix-diagram__circle.-position-b3 {
  left: 50%;
  top: 32.5%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.matrix-diagram__circle.-position-c {
  right: 6.2%;
  top: 50.1%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-c2 {
  right: 13.1%;
  top: 50.1%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-c1 {
  right: 19.5%;
  top: 50.1%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-d {
  bottom: 6.1%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.matrix-diagram__circle.-position-d2 {
  bottom: 13.1%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.matrix-diagram__circle.-position-d1 {
  bottom: 19.5%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.matrix-diagram__circle.-position-e {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.matrix-diagram__circle.-position-e1 {
  right: 39.2%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-e2 {
  right: 34.7%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.matrix-diagram__circle.-position-f {
  left: 17.8%;
  top: 17.8%
}

.matrix-diagram__circle.-position-g {
  right: 17.8%;
  top: 17.8%
}

.matrix-diagram__circle.-position-y {
  bottom: 17.8%;
  right: 17.8%
}

.matrix-diagram__circle.-position-p1 {
  right: 27.8%;
  top: 27.8%
}

.matrix-diagram__circle.-position-p2 {
  right: 23%;
  top: 23%
}

.matrix-diagram__circle.-position-p3 {
  bottom: 27.8%;
  left: 27.8%
}

.matrix-diagram__circle.-position-p4 {
  bottom: 23%;
  left: 23%
}

.matrix-diagram__circle.-position-k {
  bottom: 17.8%;
  left: 17.8%
}

.matrix-diagram__circle.-position-s1 {
  left: 27.8%;
  top: 27.8%
}

.matrix-diagram__circle.-position-s2 {
  left: 23%;
  top: 23%
}

.matrix-diagram__circle.-position-s3 {
  bottom: 23%;
  right: 23%
}

.matrix-diagram__circle.-position-s4 {
  bottom: 27.8%;
  right: 27.8%
}

.matrix-diagram__circle.-position-x {
  bottom: 33.7%;
  right: 33.7%
}

.matrix-diagram__circle.-position-x1 {
  bottom: 26.6%;
  right: 40.7%
}

.matrix-diagram__circle.-position-x2 {
  bottom: 40.6%;
  right: 26.6%
}

.matrix-diagram__circle.-size-lg {
  font-size: 3em;
  height: 7.5%;
  width: 7.5%;
  font-weight: 600;

  /*height: 9.143%;
  width: 9.143%*/
}

.matrix-diagram__circle.-size-md {
  font-size: 2em;
  height: 6%;
  width: 6%;
  font-weight: 600;
}

.matrix-diagram__circle.-size-sm {
  font-size: 2.3em;
  height: 4.572%;
  width: 4.572%;
  font-weight: 600;
}

.matrix-diagram__circle.-active {
  color: #fff
}

.matrix-diagram__circle.-purple.-active {
  background-color: #8a46a3;
  border-color: #8a46a3
}

.matrix-diagram__circle.-red.-active {
  background-color: #ef4141;
  border-color: #ef4141
}

.matrix-diagram__circle.-yellow.-active {
  background-color: #ffed47;
  border-color: #ffed47;
  color: #222831
}

.matrix-diagram__circle.-white.-active {
  background-color: #fff;
  border-color: #222831;
  color: #222831
}

.matrix-diagram__circle.-blue.-active {
  background-color: #526dfa;
  border-color: #526dfa
}

.matrix-diagram__circle.-cyan.-active {
  background-color: #45cfe1;
  border-color: #45cfe1
}

.matrix-diagram__circle.-orange.-active {
  background-color: #ffae34;
  border-color: #ffae34
}

.matrix-diagram__circle.-green.-active {
  background-color: #afde61;
  border-color: #afde61
}

.matrix-diagram__number {
  font-size: 1.5em;
  min-width: 14px;
  position: absolute;
  text-align: center;
  z-index: 10
}

.matrix-diagram__number.-bold {
  font-weight: 700
}

.matrix-diagram__number.-position-b8 {
  right: 40.2%;
  top: 3.3%
}

.matrix-diagram__number.-position-b7 {
  right: 36.9%;
  top: 4.8%
}

.matrix-diagram__number.-position-k2 {
  right: 34.1%;
  top: 5.8%
}

.matrix-diagram__number.-position-k1 {
  right: 30.5%;
  top: 7.4%
}

.matrix-diagram__number.-position-k4 {
  right: 27.2%;
  top: 8.8%
}

.matrix-diagram__number.-position-k3 {
  right: 24.3%;
  top: 9.9%
}

.matrix-diagram__number.-position-k5 {
  right: 21%;
  top: 11.4%
}

.matrix-diagram__number.-position-k8 {
  right: 10.5%;
  top: 21.9%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-k8 {
    right: 10.9%;
    top: 22.3%
  }
}

.matrix-diagram__number.-position-k7 {
  right: 9.2%;
  top: 25.2%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-k7 {
    right: 9.6%;
    top: 25.6%
  }
}

.matrix-diagram__number.-position-k6 {
  right: 7.8%;
  top: 28.3%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-k6 {
    right: 8.2%;
    top: 28.7%
  }
}

.matrix-diagram__number.-position-c4 {
  right: 6.5%;
  top: 31.2%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-c4 {
    right: 6.9%;
    top: 31.6%
  }
}

.matrix-diagram__number.-position-c6 {
  right: 5.4%;
  top: 34.3%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-c6 {
    right: 5.8%;
    top: 34.7%
  }
}

.matrix-diagram__number.-position-c5 {
  right: 4%;
  top: 37.5%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-c5 {
    right: 4.4%;
    top: 37.9%
  }
}

.matrix-diagram__number.-position-c7 {
  right: 2.3%;
  top: 41.2%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-c7 {
    right: 2.7%;
    top: 41.6%
  }
}

.matrix-diagram__number.-position-y3 {
  bottom: 40.2%;
  right: 2.8%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-y3 {
    bottom: 40.6%;
    right: 3.2%
  }
}

.matrix-diagram__number.-position-y2 {
  bottom: 37.3%;
  right: 4%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-y2 {
    bottom: 37.7%;
    right: 4.4%
  }
}

.matrix-diagram__number.-position-y4 {
  bottom: 34%;
  right: 5.2%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-y4 {
    bottom: 34.4%;
    right: 5.6%
  }
}

.matrix-diagram__number.-position-y1 {
  bottom: 30.8%;
  right: 6.5%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-y1 {
    bottom: 31.2%;
    right: 6.9%
  }
}

.matrix-diagram__number.-position-y6 {
  bottom: 27.7%;
  right: 7.7%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-y6 {
    bottom: 28.1%;
    right: 8.1%
  }
}

.matrix-diagram__number.-position-y5 {
  bottom: 24.2%;
  right: 9.2%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-y5 {
    bottom: 24.6%;
    right: 9.6%
  }
}

.matrix-diagram__number.-position-y7 {
  bottom: 20.8%;
  right: 10.5%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-y7 {
    bottom: 21.2%;
    right: 10.9%
  }
}

.matrix-diagram__number.-position-d6 {
  bottom: 11.1%;
  right: 21%
}

.matrix-diagram__number.-position-y8 {
  bottom: 9.7%;
  right: 24.3%
}

.matrix-diagram__number.-position-d5 {
  bottom: 8.4%;
  right: 27.4%
}

.matrix-diagram__number.-position-d4 {
  bottom: 6.5%;
  right: 31.3%
}

.matrix-diagram__number.-position-d8 {
  bottom: 5.1%;
  right: 35%
}

.matrix-diagram__number.-position-d7 {
  bottom: 3.7%;
  right: 38.7%
}

.matrix-diagram__number.-position-d9 {
  bottom: 2.3%;
  right: 42.8%
}

.matrix-diagram__number.-position-g3 {
  bottom: 2.5%;
  left: 41.7%
}

.matrix-diagram__number.-position-g2 {
  bottom: 3.7%;
  left: 38.6%
}

.matrix-diagram__number.-position-g4 {
  bottom: 5%;
  left: 35.3%
}

.matrix-diagram__number.-position-g1 {
  bottom: 6.5%;
  left: 31.3%
}

.matrix-diagram__number.-position-g7 {
  bottom: 7.8%;
  left: 28.3%
}

.matrix-diagram__number.-position-g5 {
  bottom: 9.1%;
  left: 25.3%
}

.matrix-diagram__number.-position-g6 {
  bottom: 10.5%;
  left: 22.1%
}

.matrix-diagram__number.-position-t3 {
  bottom: 21.8%;
  left: 10.4%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-t3 {
    bottom: 22.2%;
    left: 10.8%
  }
}

.matrix-diagram__number.-position-t2 {
  bottom: 24.9%;
  left: 9.4%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-t2 {
    bottom: 25.3%;
    left: 9.8%
  }
}

.matrix-diagram__number.-position-t4 {
  bottom: 28%;
  left: 7.9%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-t4 {
    bottom: 28.4%;
    left: 8.3%
  }
}

.matrix-diagram__number.-position-t1 {
  bottom: 31.4%;
  left: 6.3%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-t1 {
    bottom: 31.6%;
    left: 6.7%
  }
}

.matrix-diagram__number.-position-t6 {
  bottom: 34.3%;
  left: 5.3%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-t6 {
    bottom: 34.7%;
    left: 5.7%
  }
}

.matrix-diagram__number.-position-t5 {
  bottom: 37.6%;
  left: 4.1%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-t5 {
    bottom: 38%;
    left: 4.1%
  }
}

.matrix-diagram__number.-position-t7 {
  bottom: 41.2%;
  left: 2.3%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-t7 {
    bottom: 41.6%;
    left: 2.7%
  }
}

.matrix-diagram__number.-position-a5 {
  left: 2.8%;
  top: 40.4%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-a5 {
    left: 3.2%;
    top: 40.8%
  }
}

.matrix-diagram__number.-position-a4 {
  left: 4.1%;
  top: 37.3%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-a4 {
    left: 4.5%;
    top: 37.7%
  }
}

.matrix-diagram__number.-position-a6 {
  left: 5.2%;
  top: 34%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-a6 {
    left: 5.6%;
    top: 34.4%
  }
}

.matrix-diagram__number.-position-f1 {
  left: 6.4%;
  top: 30.8%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-f1 {
    left: 6.8%;
    top: 31.2%
  }
}

.matrix-diagram__number.-position-f3 {
  left: 7.8%;
  top: 27.6%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-f3 {
    left: 8.2%;
    top: 28%
  }
}

.matrix-diagram__number.-position-f2 {
  left: 9.3%;
  top: 24.4%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-f2 {
    left: 9.7%;
    top: 24.8%
  }
}

.matrix-diagram__number.-position-f4 {
  left: 10.7%;
  top: 20.7%
}

@media only screen and (min-width: 400px) {
  .matrix-diagram__number.-position-f4 {
    left: 11.1%;
    top: 21.1%
  }
}

.matrix-diagram__number.-position-f7 {
  left: 22.1%;
  top: 10.8%
}

.matrix-diagram__number.-position-f6 {
  left: 25.5%;
  top: 9.4%
}

.matrix-diagram__number.-position-f8 {
  left: 28.8%;
  top: 8%
}

.matrix-diagram__number.-position-f5 {
  left: 32%;
  top: 6.4%
}

.matrix-diagram__number.-position-b5 {
  left: 35.1%;
  top: 5.3%
}

.matrix-diagram__number.-position-b4 {
  left: 38.4%;
  top: 4%
}

.matrix-diagram__number.-position-b6 {
  left: 42%;
  top: 3%
}
.small-diagram-values__value.-bottom-left {
  bottom: 0;
  left: 0
}

.small-diagram-values__value.-center {
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.text-block p:last-child {
  margin-bottom: 0
}

.custom-table-responsive {
  margin-bottom: 10%;
  background-color: #efefef;
  padding: 4px;
  border-radius: 10px; }

.custom-table {
  display:table;
}
.custom-table thead tr, .custom-table thead th
{

  border-top: none;
  border-bottom: none !important;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .1rem;
  height: 2.2em;
  text-align: left;
  padding-left: 10px;
  padding-top: 1em;

}
.custom-table tbody th, .custom-table tbody td {
  color: #777;
  font-weight: 400;
  padding-bottom: 1em;
  padding-top: 1em;
  display:table-cell;

}
.custom-table tbody th small, .custom-table tbody td small {
  color: #b3b3b3;
  font-weight: 300;
}
.custom-table tbody tr:not(.spacer) {
  border-radius: 7px;
  overflow: hidden;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.custom-table tbody tr:not(.spacer):hover {
  -webkit-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
}
.custom-table tbody tr th, .custom-table tbody tr td {
  background: #fff;
  border: none;
  padding-left: 10px;
}
.custom-table tbody tr th:first-child, .custom-table tbody tr td:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.custom-table tbody tr th:last-child, .custom-table tbody tr td:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.custom-table tbody tr.spacer td {
  padding: 0 !important;
  height: 10px;
  border-radius: 0 !important;
  background: #efefef
}
.custom-table tbody tr.active {
  opacity: .4; }

</style>