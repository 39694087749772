import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
/*import MainPage from '@/pages/mainPage.vue'*/
import CalcPage from "@/pages/calcPage"
/*import InfoPage from "@/pages/infoPage"*/
import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
   // { path: '/', component: MainPage },
    { path: '/', component: CalcPage},
    { path: '/calc', component: CalcPage },
    //{ path: '/info', component: InfoPage }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})


const app = createApp(App)

app.use(ElementPlus)
app.use(router)
app.mount('#app')
